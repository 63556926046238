<template>
  <div>
    <a-row type="flex">
      <a-col flex="1 1 200px">
        <a-typography-title :level="2">Collections</a-typography-title>
      </a-col>
      <a-col flex="0 1 200px">
        <a-button
          size="large"
          block
          danger
          style="font-size: 18px; font-weight: bold"
          @click="showModal"
        >
          Create Collection</a-button
        >
      </a-col>
    </a-row>
    <a-modal
      :footer="null"
      v-model:visible="visible"
      title="Add Catalogue"
      @ok="handleOk"
    >
      <CreateForm />
    </a-modal>
    <a-divider
      orientation="right"
      style="height: 1px; background-color: #d7d7d7"
    />
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import CreateForm from './CreateCollection.vue'
export default defineComponent({
  components: {
    CreateForm
  },
  setup() {
    const visible = ref(false)

    const showModal = () => {
      visible.value = true
    }

    const handleOk = (e) => {
      console.log(e)
      visible.value = false
    }

    return {
      visible,
      showModal,
      handleOk
    }
  }
})
</script>
