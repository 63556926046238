<template>
  <a-spin :spinning="requestLoading">
    <div>
      <Heading />
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="All Collections"
          ><AllCollectionsTable :collections="collections"
        /></a-tab-pane>
        <a-tab-pane key="2" tab="Published Collections" force-render
          ><PublishedCollections :collections="publishedCollections"
        /></a-tab-pane>
        <a-tab-pane key="3" tab="Archived Collections"
          ><ArchivedCollections :collections="archivedCollections"
        /></a-tab-pane>
      </a-tabs>
    </div>
  </a-spin>
</template>
<script>
import Heading from './components/Header.vue'
import AllCollectionsTable from '@/views/Collections/components/tables/CollectionsTable'
import PublishedCollections from '@/views/Collections/components/tables/PublishedCollections'
import ArchivedCollections from '@/views/Collections/components/tables/ArchivedCollections'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import useLoadingState from '@/use/loading-state'
export default {
  components: {
    Heading,
    AllCollectionsTable,
    PublishedCollections,
    ArchivedCollections
  },
  setup() {
    const { requestLoading } = useLoadingState()
    const store = useStore()
    let activeKey = ref('1')
    let collections = computed(() => store.getters['collection/GetCollections'])
    let publishedCollections = computed(
      () => store.getters['collection/GetPublishedCollections']
    )
    let archivedCollections = computed(
      () => store.getters['collection/GetUnPublishedCollections']
    )
    const currentUser = computed(() => store.getters['auth/GetCurrentUser'])

    store.dispatch('collection/FetchCollections', {
      designerId: currentUser.value._id
    })
    return {
      activeKey,
      collections,
      publishedCollections,
      archivedCollections,
      requestLoading
    }
  }
}
</script>
