<template>
  <a-row>
    <a-form :model="Collection">
      <label for="Collection Name">Collection Name</label>
      <a-input class="mb-5" v-model:value="Collection.name" />
      <label for="Description">Description</label>
      <a-textarea v-model:value="Collection.description" allow-clear />
      <a-form-item class="my-5">
        <label for="image">Cover Image</label>
        <br />
        <a-input type="file" @change="handleImageChange" />
      </a-form-item>
      <a-form-item style="margin-left: 110px; border-radius: 5px">
        <app-button
          buttonText="Create Collection"
          :loading="requestLoading"
          @buttonAction="handleSubmit"
        />
      </a-form-item>
    </a-form>
  </a-row>
</template>
<script>
// import { UploadOutlined } from '@ant-design/icons-vue'
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import useLoadingState from '@/use/loading-state'
import useNotifier from '@/use/notifier'
import AppButton from '@/components/base/AppButton.vue'
export default {
  components: { AppButton },
  setup() {
    // external composition functions
    const { requestLoading } = useLoadingState()
    useNotifier()
    // end of composition functions
    const store = useStore()
    // local state
    const state = reactive({
      Collection: {
        name: '',
        description: '',
        image: ''
      }
    })
    // methods
    const handleImageChange = (e) => {
      let formData = new FormData()
      formData.append('photo', e.target.files[0])
      state.Collection.image = formData
    }
    const handleSubmit = () => {
      let payLoad = {
        designer: store.state.auth.currentUser._id,
        name: state.Collection.name,
        description: state.Collection.description,
        image: state.Collection.image
      }
      store
        .dispatch('collection/CreateCollections', payLoad)

        .catch((error) => {
          console.log('error during validation', error)
        })
    }

    // return variables and functions to be used within the template
    return { ...toRefs(state), handleSubmit, requestLoading, handleImageChange }
  }
}
</script>
<style>
.components-input-demo-size .ant-input {
  width: 200px;
  margin: 0 8px 8px 0;
}
.button {
  width: 300px;
  margin: auto;
}
</style>
