<template>
  <a-card style="background-color: #ffffff; border-radius: 10px">
    <a-table :columns="columns" :data-source="tableData.data">
      <template #tableImg="{ record }">
        <!-- <img
          src="https://via.placeholder.com/100"
          alt=""
          v-if="record.coverPhoto === ''"
        /> -->
        <a-image
          :src="record.coverPhoto"
          fallback="https://via.placeholder.com/100"
          class="w-32 h-28"
        />
      </template>
      <template #action="{ record }">
        <span>
          <a-dropdown>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="viewLook(record)">View Looks</a-menu-item>
                <a-menu-item
                  v-if="!record.published"
                  @click="togglePublish(record)"
                  >Publish</a-menu-item
                >
                <a-menu-item
                  v-if="record.published"
                  @click="togglePublish(record)"
                  >Archive</a-menu-item
                >
                <a-menu-item>Edit</a-menu-item>
                <a-menu-item @click="handleDelete(record)">Delete</a-menu-item>
              </a-menu>
            </template>
            <a>
              <EllipsisOutlined style="font-size: 25px; color: #000" />
            </a>
          </a-dropdown>
        </span>
      </template>
    </a-table>
  </a-card>
</template>
<script>
import { EllipsisOutlined } from '@ant-design/icons-vue'
import { reactive, toRefs, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { DateFormatter } from '@/helpers/DateFormatter'
import { useRouter } from 'vue-router'

const columns = [
  {
    title: 'Cover Image',
    key: 'tags',
    dataIndex: 'tags',
    slots: { customRender: 'tableImg' }
  },
  {
    title: 'Collection Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Total Looks',
    dataIndex: 'totalLooks',
    key: 'totalLooks'
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: 'Action',
    key: 'action',
    slots: { customRender: 'action' }
  }
]
export default {
  components: {
    EllipsisOutlined
  },
  props: {
    collections: {
      type: Object,
      default: function () {
        return { data: [] }
      }
    }
  },
  setup(props) {
    const router = useRouter()
    const store = useStore()

    const state = reactive({
      tableData: {},
      columns
    })
    const setTableData = (collections) => {
      state.tableData = { ...collections }
      state.tableData.data.forEach((collection) => {
        collection.createdAt = DateFormatter(collection.createdAt)
        collection.totalLooks = collection.looks.length
      })
    }
    const togglePublish = (record) => {
      const payload = {
        collectionId: record._id
      }
      store.dispatch('collection/PublishCollection', payload)
    }

    const viewLook = (record) => {
      router.push({
        name: 'CollectionLooks',
        params: { collectionId: record._id }
      })
    }
    const handleDelete = (record) => {
      const payload = {
        collectionId: record._id
      }
      store.dispatch('collection/DeleteCollection', payload)
    }

    onMounted(() => {
      setTableData(props.collections)
    })
    watch(
      () => props.collections,
      (newCollection) => {
        setTableData(newCollection)
      }
    )
    return { ...toRefs(state), togglePublish, viewLook, handleDelete }
  }
  // return variables and functions to be used within the template
}
</script>
